//Initialize Foundation
$(document).foundation();



/*
 * Deep linking for Foundation 6 Tabs using Javascript
 * * We will check if we have a hash in the page url
 */
if(window.location.hash) {
	// We have an anchor link in our URL
	var url = window.location.hash;
	var hash = url.substring(url.indexOf("#")+1);
	if($('#'+hash).length) {
    // Insert id of the <ul> that holds the links to the tabs
    $('.tabs').find('a').each(function(e) {
        var thishref = $(this).attr('href');
        if(thishref == "#"+hash) {
            // Click the link to activate the tab
                $(this).click();
            }
        });
 
    }
}